
import { defineComponent } from "vue";
import { drawGraph } from "./graph";
import { decode, encode, getInfoFromText, TreeNode } from "./huffman";

export default defineComponent({
  name: "App",
  data() {
    return {
      content: "",
      needsDecode: "",
      needsEncode: "",
      modalShow: false,
      huffman: {
        frequency: [] as [string, number][],
        codes: {} as Map<string, string>,
        rootNode: {} as TreeNode | undefined,
      },
    };
  },
  computed: {},
  methods: {
    computeRealContent() {
      // 根据规则计算出真实字符串
      // @ 清除前面(换行符后)的所有内容， #删除上一个字符
      const tempCharList: Array<string> = [];
      this.content.split("").forEach((char) => {
        if (char === "@") {
          const lastIndexOfNewLine = tempCharList.lastIndexOf("\n") + 1;
          tempCharList.splice(
            lastIndexOfNewLine,
            tempCharList.length - lastIndexOfNewLine
          );
        } else if (char === "#") tempCharList.pop();
        else tempCharList.push(char);
      });
      this.content = tempCharList.join("");

      this.getHuffman();
    },
    getHuffman() {
      [
        this.huffman.codes,
        this.huffman.frequency,
        this.huffman.rootNode,
      ] = getInfoFromText(this.content);
    },
    rawChar(char: string): string {
      return char
        .replace("\n", "\\n")
        .replace("\t", "\\t")
        .replace(" ", "Space");
    },
    updateNeedsEncode() {
      this.needsEncode = decode(this.needsDecode, this.huffman.codes);
    },
    updateNeedsDecode() {
      this.needsDecode = encode(this.needsEncode, this.huffman.codes);
    },
    drawGraph: drawGraph,
  },
});
